import Usuario from "../interfaces/Usuario.interface";

const getLista = (): Usuario[] => {
    const lista: Usuario[] = [
        {
            username: "user_admin",
            password: "!P@ssw0rd2024#@!",
        }
    ];
    return lista;
}

const UsuarioData = {
    getLista,
}

export default UsuarioData;