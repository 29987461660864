import { FC, useState } from "react";
import Login from "./pages/Login.page";
import Panel from "./pages/Panel.page";


const App: FC = () => {

  const [value, setValue] = useState<boolean>(false);

  const onChangeValue = (newValue: boolean): void => {
    setValue(newValue);
  }

  return (
    <>
      {value ? <Panel /> : <Login onChangeValue={onChangeValue} />}
    </>
  );
}

export default App;
