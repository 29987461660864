

const colors = {
    blue: "#114b7b",
    blue01: "#5eaad1",
    grey: "#e7ebec",
    blanco: "#FFFFFF",

};


export default colors;