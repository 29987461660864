import { FC, useState, ChangeEvent, KeyboardEvent, FocusEvent, useEffect } from "react";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { Lock, LockOpen } from "@material-ui/icons";
import Usuario from "../interfaces/Usuario.interface";
import UsuarioModel from "../models/Usuario.model";
import Components from "../tools/Components.tool";
import colors from "../config/Colors.config";
import Swal from "sweetalert2";

import iconSecurity from "../assets/icon/proteger.png";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";

const Login: FC<{ onChangeValue: any }> = ({ onChangeValue }) => {

    const [usuario, setUsuario] = useState<Usuario>(UsuarioModel.getEstructura());
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [device, setDevice] = useState<Device>(DeviceConfig.data());
    const onChangeUsuario = (name: string, value: string | number): void => {
        setUsuario((prev: Usuario) => ({ ...prev, [name]: value }));
    }

    useEffect(() => {
        const device: Device = DeviceConfig.data();
        setDevice(device);
    }, []);

    const login = (): void => {
        const value: boolean = UsuarioModel.login(usuario);
        if (value) onChangeValue(value); else showMessageAccessDenied();
    }

    const showMessageAccessDenied = (): void => {
        Swal.fire({
            icon: "warning",
            title: "Acceso Denegado",
            text: "Nombre de Usuario y/o Contraseña incorrectos",
            showConfirmButton: true,
            confirmButtonColor: colors.blue,
            denyButtonColor: colors.blue,
            cancelButtonText: "OK",
            // confirmButtonText: "Si",
            reverseButtons: true,
            // showCancelButton: true,
            timer: 10000,
            timerProgressBar: true,
            // footer: ` <label> <i> No podrás recuperar la cuenta actualmente vinculada </i> </label>`
        });
    }

    return (
        <section style={{ height: "100vh", width: "100%", backgroundColor: colors.blue, }} >
            <div style={{ height: device.isDesktop ? "10vh" : "3vh" }} ></div>

            <Row style={{ height: "80vh", width: device.isDesktop ? "30%" : "90%", marginRight: device.isDesktop ? "35%" : "5%", marginLeft: device.isDesktop ? "35%" : "5%", borderRadius: 12, backgroundImage: "linear-gradient(to bottom, #ffffff, #fafafa, #f6f5f5, #f1f0f0, #edebeb, #e9e7e7, #e6e3e3, #e2dfdf, #dedbdb, #dbd7d7, #d7d3d3, #d4cfcf)" }} >
                <Col style={{ borderRadius: 12 }} >
                    <Row style={{ marginTop: 20, marginRight: "5%", marginLeft: "5%" }} >
                        <Col style={{ textAlign: "center" }} >
                            <img src={iconSecurity} alt="no data" style={{ height: 150 }} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginRight: device.isDesktop ? "5%" : "2%", marginLeft: device.isDesktop ? "5%" : "2%" }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: colors.blue, fontSize: 18, fontWeight: "bold" }} >
                                    Nombre de Usuario
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{ boxShadow: "none", borderColor: colors.grey, backgroundColor: colors.blanco }}
                                        value={usuario.username}
                                        id="username"
                                        name="username"
                                        onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                            onChangeUsuario(name, value);
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            if (key === "Enter") Components.focus("password");
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20, marginRight: device.isDesktop ? "5%" : "2%", marginLeft: device.isDesktop ? "5%" : "2%" }} >
                        <Col>
                            <FormGroup>
                                <Label style={{ color: "", fontSize: 18, fontWeight: "bold" }} >
                                    Contraseña
                                </Label>
                                <InputGroup>
                                    <Input
                                        style={{ boxShadow: "none", borderColor: colors.grey, backgroundColor: colors.blanco, borderRightColor: colors.blanco }}
                                        value={usuario.password}
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        onChange={({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
                                            onChangeUsuario(name, value);
                                        }}
                                        onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                            // if (key === "Enter") login();
                                        }}
                                        onFocus={(event: FocusEvent<HTMLInputElement>) => {
                                            event.target.select();
                                        }}
                                    />
                                    <InputGroupText
                                        style={{ backgroundColor: colors.blanco, cursor: "pointer", borderColor: "transparent", borderTopColor: colors.grey, borderLeftColor: colors.blanco, borderBottomColor: colors.grey }}
                                        onClick={() => { setShowPassword(!showPassword); Components.focus("password"); }}
                                    >
                                        {showPassword ? <LockOpen style={{ color: colors.blue }} /> : <Lock style={{ color: colors.blue }} />}
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        device.isDesktop ?
                            <Row style={{ marginTop: 20, marginRight: device.isDesktop ? "5%" : "2%", marginLeft: device.isDesktop ? "5%" : "2%" }} >
                                <Col style={{ textAlign: "left" }} >
                                    <Button
                                        style={{ backgroundColor: colors.blanco, borderColor: colors.blue, boxShadow: "none", color: colors.blue, fontWeight: "bold", width: "100%" }}
                                        onClick={() => setUsuario(UsuarioModel.getEstructura())}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col style={{ textAlign: "right" }} >
                                    <Button
                                        style={{ backgroundColor: colors.blue, boxShadow: "none", color: colors.blanco, fontWeight: "bold", width: "100%" }}
                                        onClick={() => login()}
                                    >
                                        Desbloquear
                                    </Button>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row style={{ marginTop: 20, marginRight: device.isDesktop ? "5%" : "2%", marginLeft: device.isDesktop ? "5%" : "2%" }} >
                                    <Col style={{ textAlign: "right" }} >
                                        <Button
                                            style={{ backgroundColor: colors.blue, boxShadow: "none", color: colors.blanco, fontWeight: "bold", width: "100%" }}
                                            onClick={() => login()}
                                        >
                                            Desbloquear
                                        </Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10, marginRight: device.isDesktop ? "5%" : "2%", marginLeft: device.isDesktop ? "5%" : "2%" }} >
                                    <Col style={{ textAlign: "left" }} >
                                        <Button
                                            style={{ backgroundColor: colors.blanco, borderColor: colors.blue, boxShadow: "none", color: colors.blue, fontWeight: "bold", width: "100%" }}
                                            onClick={() => setUsuario(UsuarioModel.getEstructura())}
                                        >
                                            Cancelar
                                        </Button>
                                    </Col>
                                </Row>

                            </>
                    }

                </Col>
            </Row>

        </section >
    );
};

export default Login