import UsuarioData from "../data/usuarios.data";
import Usuario from "../interfaces/Usuario.interface";



const getEstructura = (): Usuario => {
    const data: Usuario = {
        username: "",
        password: "",
        esHumano: 0,
    }
    return data;
};

const login = (usuario: Usuario): boolean => {
    const usuarioLista: Usuario[] = UsuarioData.getLista();
    let value: boolean = false;
    const data: Usuario | undefined = usuarioLista.find((item: Usuario) => (item.username === usuario.username && item.password === usuario.password));
    if (data) value = true;
    return value;
}

const UsuarioModel = {
    getEstructura,
    login,
};

export default UsuarioModel;