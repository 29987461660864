import { FC, useEffect, useState } from "react";
import colors from "../config/Colors.config";

import iconDownload from "../assets/icon/computacion-en-la-nube.png";
import { Button, Col, DropdownItem, Label, Row } from "reactstrap";
import Device from "../interfaces/Device.interface";
import DeviceConfig from "../config/Device.config";

const Panel: FC = () => {

    const [device, setDevice] = useState<Device>(DeviceConfig.data());

    const descargarArchivo = (phat: string, nombreArchivo: string) => {
        const link = document.createElement('a');
        link.href = phat; //'/assets/files/InformacionNorte.rar';
        link.download = nombreArchivo; // 'Informacion Norte.zip';
        link.click();
    };

    useEffect(() => {
        const device: Device = DeviceConfig.data();
        setDevice(device);
    }, []);

    return (
        <section style={{ height: "100vh", width: "100%", backgroundColor: colors.blanco, overflowY: "auto" }} >
            <div style={{ height: device.isDesktop ? "20vh" : "5vh" }} ></div>
            <center>
                <Row style={{ marginRight: "10%", marginLeft: "10%" }} >
                    <Col style={{ minWidth: device.isDesktop ? "50%" : "100%", width: device.isDesktop ? "50%" : "100%", maxWidth: device.isDesktop ? "50%" : '"100%"', }} >
                        <Row style={{ marginRight: 0, marginLeft: 0 }} >
                            <Col>
                                <img
                                    src={iconDownload}
                                    alt="No icon"
                                    onClick={() => {
                                        const path: string = '/assets/files/InformacionNorte.rar';
                                        const nombreArchivo: string = 'Informacion Norte.rar';
                                        descargarArchivo(path, nombreArchivo);
                                    }}
                                    style={{ cursor: "pointer", height: 150 }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 20 }} >
                            <Col>
                                <Label style={{ fontSize: 20 }} >
                                    Información Norte
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                            <Col>
                                <Button
                                    onClick={() => {
                                        const path: string = '/assets/files/InformacionNorte.rar';
                                        const nombreArchivo: string = 'Informacion Norte.rar';
                                        descargarArchivo(path, nombreArchivo);
                                    }}
                                    style={{ boxShadow: "none", fontSize: 18, color: colors.blanco, borderRadius: 12, backgroundColor: colors.blue, fontWeight: "bold" }}
                                >
                                    Descargar Archivo
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    {device.isMovil && <div style={{ height: "3vh" }} ></div>}
                    {device.isMovil && <DropdownItem divider />}
                    {device.isMovil && <div style={{ height: "3vh" }} ></div>}
                    <Col style={{ minWidth: device.isDesktop ? "50%" : "100%", width: device.isDesktop ? "50%" : "100%", maxWidth: device.isDesktop ? "50%" : '"100%"', }} >
                        <Row style={{ marginRight: 0, marginLeft: 0 }} >
                            <Col>
                                <img
                                    src={iconDownload}
                                    alt="No icon"
                                    onClick={() => {
                                        const path: string = '/assets/files/INF2.rar';
                                        const nombreArchivo: string = 'INF 2.rar';
                                        descargarArchivo(path, nombreArchivo);
                                    }}
                                    style={{ cursor: "pointer", height: 150 }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 20 }} >
                            <Col>
                                <Label style={{ fontSize: 20 }} >
                                    Información 2
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 5 }} >
                            <Col>
                                <Button
                                    onClick={() => {
                                        const path: string = '/assets/files/INF2.rar';
                                        const nombreArchivo: string = 'INF 2.rar';
                                        descargarArchivo(path, nombreArchivo);
                                    }}
                                    style={{ boxShadow: "none", fontSize: 18, color: colors.blanco, borderRadius: 12, backgroundColor: colors.blue, fontWeight: "bold" }}
                                >
                                    Descargar Archivo
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </center>


        </section>
    );
};

export default Panel;